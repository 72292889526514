<template>

<app-page>

	<app-page-head noun="access" title="Access" :count="list.length">

		<app-page-head-action route="Access" :params="{ access: 'new' }" icon="create" tooltip="Add user" />
		
	</app-page-head>

	<app-page-content :is-empty="!list.length" empty-message="No users found.">

		<com-item v-for="item in list" :key="item.id" :item="item" />

	</app-page-content>

	<app-page-foot />

</app-page>

</template>

<script>

import mixList from '@/mixin/list'

export default {

	mixins: [mixList],

	components: {
		'com-item': () => import('./accesses/Item')
	},

	data: function() {

		return {
			store: 'access'
		}

	}

}

</script>

<style scoped>

</style>
